import axios from "axios";

export default {
  data: {
    searchText: "",
    articles: [],
    blocks: []
  },
  computed: {
    searching: function() {
      return this.searchText.length > 0;
    },
    hit: function() {
      return this.articles.length > 0 || this.blocks.length > 0;
    }
  },
  methods: {
    search: function() {
      if (this.searchText.length == 0) {
        this.articles = [];
        this.blocks = [];
        return false;
      }
      axios.get("/top/search.json?search_text=" + this.searchText).then(res => {
        if (res.data.articles) {
          this.articles = res.data.articles;
        }
        if (res.data.blocks) {
          this.blocks = res.data.blocks;
        }
      });
    },
    getModalContents: function(articleId) {
      const e = document.getElementById("modal-article");
      axios.get("/top/modal_contents?id=" + articleId).then(res => {
        e.innerHTML = res.data;
        e.style.cssText = "display: block;";
        e.classList.add("uk-open");
      });
    },
    truncate: function(str, length) {
      if (str.length > length) {
        return str.substr(0, length) + "...";
      } else {
        return str;
      }
    },
    clearSearch: function() {
      this.searchText = "";
    }
  }
};
